<template>
    <div>
		<b-row no-gutters>
            <div class="col-xs-6 col-md-3 col-sm-12">
				<b-carousel :interval="0" controls indicators v-if="room.RoomImg">
                    <b-carousel-slide v-for="(img, index) in room.multimedias" :key="index" >
                    <template #img>
                        <b-card-img	:src="img.multimedia" height="320" />
                    </template>
                    </b-carousel-slide>
                </b-carousel>
                <b-card-img v-else style="height:100%;" :src="room.multimedias[0].multimedia"/>
			</div>
            <div class="col-xs-6 col-md-5 col-sm-12" style="border-right: 1px solid #e0e0e0;">
				<b-card-body>
                    <h6 class="item-name mb-1">
                        <b class="text-body">  {{ room.Name }}</b>
                    </h6>
                    <div v-if="room.rates.length">
                        <b-form-group v-if="!isLoadingRates">
                            <v-select
                                label="MealPlanDescriptionCustom"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="room.rates"
                                v-model="room.detailSelected"
                                :clearable="false"
                                @input="setRate(room.detailSelected)"
                            >
                            </v-select>
                        </b-form-group>
                        <div class="text-center" v-if="isLoadingRates">
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Buscando tarifas</strong>
                        </div>
                    </div>
                    <b-alert show variant="danger" v-if="!room.rates.length">
                        <div class="alert-body text-center"> 😣 <span>No hay una tarifa disponible para su búsqueda</span></div>
                    </b-alert>
                    <b-card-text class="long-description text-justify" v-if="bookingSelected.dingusbooking">
                        <b>Reserva: {{bookingSelected.dingusbooking}}</b>
                    </b-card-text>
                    <b-card-text class="long-description text-justify">
                    {{ room.DescriptiveText }}
                    </b-card-text>
                    <AdditionalDataRoom  :room="room" />
                </b-card-body>
			</div>
			<div class="col-xs-6 col-md-4 col-sm-12">
				<div style="padding: 1rem; justify-content: center;">
                    <PriceMatchOptions
                      :room="room"
                      :isLoadingRates="isLoadingRates"
                      @set-is-loading-rates="setIsLoadingRates"
                      @update-booking="updateMyBooking"
                    />
                </div>
            </div>
        </b-row>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
//Componentes
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import PriceMatchOptions from '@/modules/mySales/components/orderDetail/bookings/PriceMatchOptions'
import AdditionalDataRoom from '@/modules/mySales/components/orderDetail/bookings/AdditionalDataRoom'

export default {
    props:{
        room:{
            type: Object,
            required: true
        },
    },
    components: {
        flatPickr,
        vSelect,
        PriceMatchOptions,
        AdditionalDataRoom
    },
    data() {
        return {
            isLoadingRates: false
        }
    },
    computed:{
        ...mapState('mySales', ['bookingSelected'])
    },
    methods: {
        ...mapMutations('mySales', ['setRateSelectedRoom']),
        setRate(detailSelected){
            if( detailSelected ){
                const payload = { keyRoom: this.room.keyRoom, rate: detailSelected }
                this.setRateSelectedRoom(payload)
            }
        },
        setIsLoadingRates(boolean){
            this.isLoadingRates = boolean
        },
        async updateMyBooking(payload){
            this.$emit("update-booking", payload);
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
