<template>
    <div>
        <b-row class="mb-1 text-dark">
            <b-col md="3">
                <div v-if="listValidRsvs.length > 1" class="d-flex justify-content-between">
                    <span class="subtitle">Orden N.</span>
                    <b-form-select
                        v-model="searchForm.idRsv"
                        :disabled="isLoadingRooms"
                        size="sm"
                        class="font-weight-bolder subtitle w-50"
                        @input="fillDataform"
                    >
                    <option
                        v-for="(room, index) in listValidRsvs"
                        :key="index"
                        :value="room.booking.id"
                    >
                        <!-- {{ room.booking.dingusbooking != 0 ? room.booking.dingusbooking : 'Sin número' }} -->
                        {{ index + 1 }} | {{ room.invoice }}
                    </option>
                    </b-form-select>
                </div>
                <span class="subtitle" v-else-if="listValidRsvs.length > 0">Orden {{listValidRsvs[0].invoice}}</span>
            </b-col>
            <b-col md="">
                <template v-if="!!bookingToEdit && !!bookingSelected">
                    <span class="subtitle" v-if="!!bookingSelected.dingusbooking && bookingSelected.dingusbooking != 0">Reservación N. {{ bookingSelected.dingusbooking }}</span>
                    <span class="subtitle" v-else>Reserva no confirmada</span>
                </template>
            </b-col>
            <b-col md="">
                <span v-if="!!bookingToEdit && !!bookingSelected" class="subtitle mr-2"> Creado: {{ formatThisDate(selectedOrder.orderDate) }} </span>
            </b-col>
            <b-col md="2" />
        </b-row>
        <b-row v-if="!!bookingToEdit && !!bookingSelected && selectedDetail" class="booking-data">
            <b-col md="3">
                <span class="font-weight-bold">
                    {{ bookingSelected.givenname }} {{ bookingSelected.surname }}
                </span><br>
                <span class="font-weight-bold">
                    {{ bookingSelected.email }}
                </span> <br>
                <span class="font-weight-bold">
                    {{ bookingSelected.phonenumber }}
                </span>
            </b-col>
            <b-col md="">
                <span class="font-weight-bold text-secondary">
                    {{ bookingSelected.hotelname }}
                </span><br>
                <span class="font-weight-bold text-secondary">
                    {{ bookingSelected.roomtypename }}
                </span> <br>
                <span class="font-weight-bold text-secondary text-capitalize">
                    Status:
                    <span :class="`even-more-bolder text-${badgeStatus}`">
                        <b>{{selectedDetail.orderdetailstatusname}}</b>
                    </span>
                </span>
            </b-col>
            <b-col md="">
                <span class="font-weight-bold text-secondary">
                    <span class="font-weight-bolder">Meal Plan: </span>
                    {{ bookingSelected.mealplancode }}
                </span><br>
                <span class="font-weight-bold text-secondary">
                    <span class="font-weight-bolder">Rate Plan: </span>
                    {{ bookingSelected.rateplancode }}
                </span> <br>
                <span class="font-weight-bold text-secondary" >
                    <span class="font-weight-bolder">Promoción: </span>
                    {{!!bookingSelected.promotioncode ? bookingSelected.promotioncode : 'Sin promoción'}}
                </span>
            </b-col>
            <b-col md="2">
                <span class="font-weight-bold text-secondary">
                    <!-- canBeEdited: {{ bookingSelected.canBeEdited }} -->
                </span> <br>
                <span class="font-weight-bold text-secondary d-flex justify-content-between">
                    <span class="font-weight-bolder">Tarifa inicial: </span>
                    <span class="mr-1">{{formatAmount(bookingSelected.rateInit)}}</span>
                </span>
                <span class="font-weight-bold text-secondary d-flex justify-content-between">
                    <span class="font-weight-bolder">Total: </span>
                    <span class="mr-1">{{formatAmount(selectedDetail.total)}}</span>
                </span>
            </b-col>
        </b-row>
        <hr v-if="!!bookingToEdit && !!bookingSelected" class="border-dark">

        <!-- <div v-if="!canEditBooking">
            <b-alert variant="danger" show  class="mt-1">
                <div class="alert-body text-justify">
                    <p class="font-weight-bold text-danger text-center">{{ msgCanEditBooking }}</p>
                </div>
            </b-alert>
        </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters,  mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { currentDate, formatDateSpecific, toCurrency } from '@/helpers/helpers'

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    directives: {
        Ripple
    },
    props: {
        isLoadingRooms: {
            type: Boolean,
            required: true
        },
        fillDataform: {
            type: Function,
            required: true
        },
        listValidRsvs:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            max: 255,
            isOpenModalDateOperation: false,
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,
            fechaActual: currentDate()
        }
    },
    computed: {
        ...mapState('auth',['user']),
        ...mapState('mySales',['orderDetail','aBookingIsUpdating','bookingToEdit','bookingSelected', 'searchForm', 'selectedOrder']),
        ...mapGetters('mySales',['orderproductProducts']),
        selectedDetail(){
            return this.orderDetail?.bookings?.find(detail => detail.id == this.bookingSelected.orderDetail) || null
        },
        badgeStatus(){
            if (
                this.selectedDetail?.orderdetailstatusname == 'Requested' ||
                this.selectedDetail?.orderdetailstatusname == 'Modified' ||
                this.selectedDetail?.orderdetailstatusname == 'Reserved'
            ) return 'warning'
            if (
                this.selectedDetail?.orderdetailstatusname == 'Paid' ||
                this.selectedDetail?.orderdetailstatusname == 'Audited' ||
                this.selectedDetail?.orderdetailstatusname == 'Operated'
            ) return 'success'
            if (
                this.selectedDetail?.orderdetailstatusname == 'Cancelled' ||
                this.selectedDetail?.orderdetailstatusname == 'Annulled' ||
                this.selectedDetail?.orderdetailstatusname == 'No Show'
            ) return 'danger'
        },
    },
    methods: {
        ...mapActions('mySales',['updateOperationDateTimeNote']),
        async setOperationDateTimeNote(orderdetail, operationdate, operationtime, note, clientnote ){
            const payload = { orderdetail, operationdate, operationtime, note, idUser: this.user.idUser, clientnote }
            // console.log(payload)
            await this.updateOperationDateTimeNote(payload)
        },
        formatThisDate(date){
            return formatDateSpecific({date, toFormat: 'ddd MM/DD/YYYY'})
        },
        formatAmount(amount){
            return toCurrency(amount)
        },
    }
}
</script>

<style>
@media print {
    body {
        visibility: hidden;
    }
    button, a{
        /* visibility: hidden; */
        display: none !important;
    }
    .head-logo{
        display: block !important;
        max-width: 200px;
    }
    #section-to-print {
        visibility: visible;
        /* position: absolute; */
        left: 0;
        top: 0;
    }
}
.even-more-bolder{
    font-weight: 900;
}
.head-logo{
    display: none;
    max-width: 200px;
    margin: 0;
}
.head__img {
    display: block;
    width: 100%;
}
.data-rsv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.not-found-btn{
    display: flex;
    justify-content: flex-end;
}
.title{
    font-size: 1.1rem;
    font-weight: bold;
}
.subtitle{
    font-size: 1.1rem;
    font-weight: bold;
}
.booking-data{
    font-size: 0.9rem
}
.select-width{
    width: 18%;
}
</style>