<template>
    <div style="height: inherit">
        <!-- Overlay -->
        <div class="body-content-overlay" />
        <div class="text-center" v-if="isLoadingRooms">
            <b-spinner label="Loading..." variant="success"/><br>
            <strong>Buscando información</strong>
        </div>
        <div v-else-if="!!bookingSelected">
            <hr v-if="bookingSelected.rooms.length">
            <b-card no-body style="max-width: 100%;"  v-for="(room, index) in bookingSelected.rooms" :key="index">
                <Room :room="room" @update-booking="updateMyBooking" />  <hr>
            </b-card>
        </div>

        <b-alert show variant="danger" class="mt-2" v-if="!bookingSelected && !isLoadingRooms">
            <div class="alert-body text-center"> 😊 <span>Se mostrarán resultados una vez se haya hecho una busqueda</span></div>
        </b-alert>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
//Componentes

import Room from '@/modules/mySales/components/orderDetail/bookings/Room'


export default {
    props:{
        isLoadingRooms:{
            type: Boolean,
            required: true
        }
    },
    components: {
        Room
    },
    computed:{
        ...mapState('mySales',['bookingSelected'])
    },
    methods: {
        ...mapMutations('mySales', ['setRateBooking']),
        setRate(booking, selectedRate){
            const { id  } = booking
            const payload = { id, selectedRate }
            if( selectedRate ){
                this.setRateBooking(payload)
            }
        },
        updateMyBooking(payload){
            this.$emit("update-booking", payload);
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
