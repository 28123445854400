<template>
    <b-card class="border">
        <b-overlay :show="isUpdatingTitular" rounded>
            <b-card-body>
                <b-card-title style="font-size:1em" class="text-justify">
                    <b-link class="card-link text-center" style="cursor:auto">
                        <h6 class="text-center" style="font-size:smaller">(Huésped Principal)</h6>
                        <h3>{{product.booking.nameprefix}}. {{product.booking.givenname}} {{product.booking.surname}}</h3>
                        <h5>{{ product.booking.roomtypename }} - {{ product.booking.hotelname }}</h5>
                        <hr>
                    </b-link>
                </b-card-title>
                <b-link class="card-link" style="cursor:auto" >
                    <b-row>
                        <b-col md="6" class="text-center">
                            <strong>Check in:</strong> {{ product.booking.dateIn }} <br>
                            <strong>Check Out:</strong> {{ product.booking.dateOut }} <br>
                            <span v-if="product.booking.dingusbooking"><strong>Reserva: </strong>{{ product.booking.dingusbooking }}</span>
                        </b-col>
                        <b-col md="6" class="text-center" :class="windowWidth > 767 ? 'd-none' : '' ">
                            ...<br><strong>Adultos: </strong> {{ product.booking.adults }} <br>
                            <strong>Orden: </strong>  {{ product.order }} <br>
                            <strong>Total: </strong> ${{ product.booking.total }}
                        </b-col>
                        <b-col md="6" class="text-center" style="border-left-style:solid;border-left-width:thin;" :class="windowWidth < 768 ? 'd-none' : '' ">
                            <strong>Adultos: </strong> {{ product.booking.adults }} <br>
                            <strong>Orden: </strong>  {{ product.order }} <br>
                            <strong>Total: </strong> ${{ product.booking.total }}
                        </b-col>
                    </b-row>
                </b-link>
                <div>
                    <hr>
                    <span class="text-center pb-1"><h5>Huéspedes</h5></span>
                </div>
                <b-row>
                    <b-col md="8" v-if="(product.booking.oldguests.length > 0)">
                        <li class="mb-1" md="6" v-for="guest in guestsInBooking" :key="guest.id">
                            {{guest.name}} {{guest.lastname}}
                            <b-badge pill class="ml-1" variant="info" v-if="!guest.adult">menor -
                                <span v-if="guest.edad"> {{guest.edad > 1 ? `${guest.edad} años` : `${guest.edad} año`}}</span>
                            </b-badge>
                        </li>
                    </b-col>
                    <b-col class="pt-1" v-else>
                        <b-badge>Esta reserva cuenta con huéspedes <br> sin configurar</b-badge>
                    </b-col>
                    <b-col>
                        <div class="float-right pt-1">
                            <b-button
                            v-if="enableActions"
                            v-b-tooltip.hover
                            title="Editar huéspedes"
                            name="check-button"
                            v-b-modal="'modal-guest-booking' + product.booking.id"
                            :variant="editGuests ? 'primary' : 'warning'"
                            ><feather-icon icon="Edit2Icon" size="14" />
                            </b-button>
                        </div>
                        <div>
                            <b-modal
                                :id="'modal-guest-booking' + product.booking.id"
                                :title="`Actualizar huéspedes | ${product.booking.roomtypename} - ${ product.booking.hotelname}`"
                                ok-only
                                no-close-on-backdrop
                                size="lg"
                                ok-title="Ok"
                                centered
                                @ok="updateReservationOwner(product)"
                            >
                                <template #modal-footer="{ ok }">
                                    <b-button
                                        variant="primary"
                                        :disabled="isUpdatingTitular"
                                        @click="ok()"
                                    > <b-spinner small v-if="isUpdatingTitular"/> Actualizar
                                    </b-button>
                                </template>
                                <GuestForm :booking="product.booking" />
                            </b-modal>
                        </div>
                    </b-col>
                </b-row>
                <hr>
            </b-card-body>
        </b-overlay>
    </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations} from 'vuex'
import { namesPrefix } from '@/data/data'
import GuestForm from '@/modules/mySales/components/orderDetail/bookings/GuestForm'
import { setGuestsInPayload } from '@/helpers/shopHelper'
import { toJson } from '@/helpers/helpers'

export default {
    components: {
        GuestForm
    },
    props:{
        product:{
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isUpdatingTitular: false,
            namesPrefix,
            editGuests: true,
            editbooking: null
        }
    },
    computed: {
        ...mapState('auth',['user']),
        ...mapState('mySales',['orders', 'selectedOrder']),
        ...mapState('appConfig',['windowWidth']),
        enableActions() {
            const seller = this.product?.saleuser_id == this.user?.idUser
            const channel = this.product?.saleuserchannelid == this.user?.canalventa?.id
            const owner = this.selectedOrder?.customer?.typeCustomer == 2

            const valid = ((seller || channel) && !owner) || false

            return valid
        },
        guestsInBooking(){
            const oldguests = this.product.booking?.oldguests?.filter(guest => !guest.ismain)
            return oldguests
        }
    },
    methods: {
        ...mapActions('mySales',['updateBookingOwner', 'getInitialDataOrderDetails']),
        ...mapMutations('mySales',['setaBookingIsUpdating']),
        async updateReservationOwner(orderDetail){
            const { booking, id } = orderDetail
            this.isUpdatingTitular = true
            const idx = toJson( booking.guests?.adults?.findIndex( main => main.mainGuest = true ))
            this.product.booking.oldguests = setGuestsInPayload(booking.guests)
            const payload = {
                id: booking.id,
                detail: id,
                email: booking.guests?.adults[idx]?.Email,
                givenname: booking.guests?.adults[idx]?.GivenName,
                surname: booking.guests?.adults[idx]?.Surname,
                nameprefix: booking.guests?.adults[idx]?.NamePrefix,
                phonenumber: booking.guests?.adults[idx]?.PhoneNumber,
                guests: booking.oldguests,
            }
            // console.log(payload)
            const response = await this.updateBookingOwner(payload)
            if (response){
                await this.getInitialDataOrderDetails( { idOrder: this.product.order} ) // vuelvo a obtener los datos de la orden
                this.isUpdatingTitular = false
            } else this.isUpdatingTitular = false
        },
        formatPhoneNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {
				event.preventDefault()
			}
        }
    }
}
</script>

