<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Detalles">
                <b-row>
                    <b-col class="col-md-6" v-for="booking in orderDetailBookings" :key="booking.id">
                        <NoteBooking :product="booking"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Huéspedes">
                <b-row>
                    <b-col class="col-md-6" v-for="booking in orderDetailBookings" :key="booking.id">
                        <TitularBooking :product="booking"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Actualizar">
                <UpdateBooking @update-booking="updateMyBooking"/>
            </b-tab>
        </b-tabs>
        <!-- Control buttons-->
        <div class="text-center d-none">
            <b-button-group class="mt-1">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex--"
            >
                Anterior
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="tabIndex++"
            >
                Siguiente
            </b-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
import {  mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Ripple from 'vue-ripple-directive'
import NoteBooking from '@/modules/mySales/components/orderDetail/NoteBooking'
import TitularBooking from '@/modules/mySales/components/orderDetail/bookings/TitularBooking'

import UpdateBooking from '@/modules/mySales/components/orderDetail/bookings/UpdateBooking'

export default {
    directives: {
        Ripple
    },
    components: {
        NoteBooking,
        TitularBooking,
        UpdateBooking
    },
    data() {
        return {
            tabIndex: 0,
            typeItems: 'bookings',
        }
    },
    computed: {
        ...mapGetters('mySales',['orderDetailBookings']),
    },
    methods: {
        updateMyBooking(){
            this.$emit("update-booking");
        }
    }
}
</script>