<template>
  <div>
    <validation-observer ref="searchFormbooking" tag="form">
      <b-form>
        <HeaderBooking class="mb-2"
          :isLoadingRooms="isLoadingRooms"
          :fillDataform="fillDataform"
          :listValidRsvs="listValidRsvs"
        />

        <b-row v-if="!!searchForm.idRsv && bookingSelected.canBeEdited">
          <b-col md="3">
            <b-form-group>
              <label class="font-weight-bold">Check in - Check out*</label>
              <flat-pickr
                v-model="searchForm.rangeDate"
                size="sm"
                :disabled="isLoadingRooms"
                class="form-control form-control-sm"
                :config="{ mode: 'range', minDate: 'today' }"
                @input="setNights"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <label class="font-weight-bold">Noches</label>
              <b-form-input v-model="searchForm.nights" disabled  size="sm"/>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <validation-provider name="Adultos" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label class="font-weight-bold">Adultos*</label>
                  <b-form-select
                    v-model="searchForm.adults"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :disabled="isLoadingRooms"
                    @change="setAdults"
                  >
                    <option
                      v-for="(adulto, index) in adultOptions"
                      :key="index"
                      :value="adulto.value"
                    >
                      {{ adulto.title }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <validation-provider name="Niños" rules="required">
              <b-form-group slot-scope="{ errors }">
                <label class="font-weight-bold">Niños*</label>
                <b-form-select
                  v-model="searchForm.numberChildren"
                  size="sm"
                  :options="childrenOptions"
                  :state="errors.length > 0 ? false : null"
                  :disabled="isLoadingRooms"
                  @change="setChilds"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label class="font-weight-bold">Código promocional</label>
              <b-form-input
                v-model="searchForm.promoCodeManual"
                size="sm"
                :disabled="isLoadingRooms"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="!!bookingSelected" class="pb-0">
            <BookingGuests :guests="bookingSelected.guests" :showGuests="showGuests" @set-show-guest="showGuestForm"/>
          </b-col>

          <b-col cols="12" class="mt-1 text-right text-nowrap">
            <b-button
              variant="warning"
              @click.prevent="resetForm"
              :disabled="isLoadingRooms"
              >Limpiar
            </b-button>
            <b-button
              v-if="isAFullUpdate"
              variant="primary"
              type="submit"
              class="ml-1"
              :disabled="isLoadingRooms || searchForm.rsv == ''"
              @click.prevent="getBookings(searchForm)"
            >
              <b-spinner small label="Loading..." variant="info" v-if="isLoadingRooms" /> Buscar
            </b-button>
            <b-button
              v-else
              variant="primary"
              type="submit"
              class="ml-1"
              :disabled="isLoadingRooms || searchForm.rsv == ''"
              @click.prevent="updateBooking(searchForm)"
            >
              <b-spinner small label="Loading..." variant="info" v-if="isLoadingRooms" /> Actualizar
            </b-button>
          </b-col>
        </b-row>

        <b-row v-else class="mb-1">
          <b-col md="3">
            <label class="font-weight-bold">Check in - Check out*</label>
              <flat-pickr
                v-model="searchForm.rangeDate"
                size="sm"
                disabled
                class="form-control form-control-sm"
                :config="{ mode: 'range' }"
              />
          </b-col>
          <b-col md="2">
            <label class="font-weight-bold">Noches</label>
            <b-form-input v-model="searchForm.nights" disabled  size="sm"/>
          </b-col>
          <b-col md="2">
            <label class="font-weight-bold">Adultos*</label>
            <b-form-select
              v-model="searchForm.adults"
              size="sm"
              disabled
              @change="setAdults"
            >
              <option
                v-for="(adulto, index) in adultOptions"
                :key="index"
                :value="adulto.value"
              >
                {{ adulto.title }}
              </option>
            </b-form-select>
          </b-col>
          <b-col md="2">
            <label class="font-weight-bold">Niños*</label>
            <b-form-select
              v-model="searchForm.numberChildren"
              size="sm"
              :options="childrenOptions"
              disabled
              @change="setChilds"
            ></b-form-select>
          </b-col>
          <b-col cols="12">
          </b-col>
          <b-col cols="6" class="mt-2" v-if="bookingSelected">
            <h6>Lista de huéspedes</h6>
            <b-row class="mt-2" v-for="(guest, idx) in bookingSelected.oldguests" :key="idx">
              <b-col md="6">
                <span class="font-weight-bold">{{ idx + 1 }} - {{ guest.name }} {{ guest.lastname }}</span>
              </b-col>
              <b-col>
                <span class="float-center"> ({{ guest.adult ? 'Adulto' : `Niño, ${guest.edad} Años` }})</span><br>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import * as moment from "moment";
import BookingGuests from '@/modules/mySales/components/orderDetail/bookings/BookingGuests'
import HeaderBooking from '@/modules/mySales/components/orderDetail/bookings/HeaderBooking'

//data seeds
import {
  adultOptions,
  childrenOptions,
  ageChildrenOptions,
  searchBookingData,
} from "@/data/data";
import {
  toJson,
  showAlertMessage,
  stringAleatorio,
  currentDate,
} from "@/helpers/helpers";
import flatPickr from "vue-flatpickr-component";

export default {
  async mounted() {
    if (!!this.detailBookingToEdit){
      const detail = this.roomsData.find(item => item.id == this.detailBookingToEdit)
      this.searchForm.idRsv = detail?.booking?.id || this.roomsData[0].booking.id
    }
    else this.roomsData.forEach((item, index) => {
      if (index == 0) this.searchForm.idRsv = item.booking.id
    })
    this.fillDataform(this.searchForm.idRsv)
    if (this.searchForm.rangeDate.includes("to")) this.setNights(this.searchForm.rangeDate)
  },
  props: {
    roomsData: {
      type: Array,
      required: true,
    },
    isLoadingRooms: {
      type: Boolean,
      required: true,
    },
    showDataTitular: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    flatPickr,
    BookingGuests,
    HeaderBooking
  },
  data() {
    return {
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      showGuests: true
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("mySales", ["bookingSelected", "searchForm", "orderDetail", "bookingToEdit", "detailBookingToEdit"]),
    listValidRsvs(item) {
      const bookings = [...this.roomsData];
      let valids = [];
      const statusBanned = ["Cancelled", "Annulled"]; // status no aceptados
      bookings.forEach((item, index) => {
        const isValidDate = moment(item.booking.dateIn).isAfter(currentDate()); //verifico si la fecha de ingreso, es mayor  la fecha actual
        //si status de item no está en los baneados y viene con dingusbooking
        if (!statusBanned.includes(item.orderdetailstatusname) && isValidDate) {
          item.fullName = `${item.booking.nameprefix} ${item.booking.givenname} ${item.booking.surname}`;
          item.indice = index + 1;
          item.canBeEdited = true
        }
        valids.push(item)
      });

      return valids;
    },
    isAFullUpdate(){
      if (this.bookingSelected) {
        const {adults, numberChildren, rangeDate, promoCodeManual} = this.searchForm
        const {adults:bookingAdults, children, rangeDate:bookingDates, promotioncode} = this.bookingSelected

        return (adults != bookingAdults || numberChildren != children || rangeDate != bookingDates || promoCodeManual != promotioncode)
      } else return false
    }
  },
  watch: {
    searchForm: {
      deep: true,
      handler() {
        const lengthRooms = this.bookingSelected ? this.bookingSelected.rooms.length : 0
        if (lengthRooms > 0) this.setRoomsBookingSelected([])
      },
    },
  },
  methods: {
    moment,
    ...mapActions("mySales", ["fetchDataBooking"]),
    ...mapMutations("mySales", [
      "setBookingSelected",
      "setBookingToEdit",
      "setDetailBookingToEdit",
      "setRoomsBookingSelected",
      "setDataSearchForm",
      "clearDataSearchForm",
      "setAgeChildsSearchForm",
      "setNumberChildrenSearchForm",
      "setSpecificAgeChilSearchForm",
    ]),

    fillDataform(value) {
      if (value != "") {
        // id:orderDetail, alias como orderDetail
        const { booking, id: orderDetail, order, firstpay, partial, orderdetailstatusname} = toJson(this.roomsData.find((item) => item.booking.id === value));
        booking.orderDetail = orderDetail;
        booking.order = order;
        booking.firstpay = firstpay;
        booking.partial = partial;

        const statusBanned = ["Cancelled", "Annulled"]; // status no aceptados
        const isValidDate = moment(booking.dateIn).isAfter(currentDate()); //verifico si la fecha de ingreso, es mayor  la fecha actual
        booking.canBeEdited = !statusBanned.includes(orderdetailstatusname) && isValidDate && this.showDataTitular

        this.setBookingSelected(booking); //muto bookingSelected
        this.setBookingToEdit(value); //muto bookingToEdit
        this.setDetailBookingToEdit(orderDetail)
        const { adults, hoteldinguscode, language, nights, children, ninios, promotioncode, rangeDate} = booking;
        const vendorcode = this.user.canalventa.dingusvendorcode || "";
        const payloadForm = {
          adults,
          hoteldinguscode,
          language,
          nights,
          children,
          promotioncode,
          rangeDate,
          vendorcode,
          idRsv: value,
        };
        payloadForm.ninos = this.createArrayNinios(ninios);
        this.setDataSearchForm(payloadForm);
      }
      if (value == "") {
        this.setBookingToEdit(null); //muto bookingToEdit en nulo
        this.setDetailBookingToEdit(null)
        this.clearDataSearchForm();
      }
    },
    resetForm() {
      showAlertMessage(`Datos limpiados`, "BellIcon", `🎉🎉 Datos de formulario limpiados`, "success", 3000, "bottom-right")
      const idRsv = structuredClone(this.searchForm.idRsv)
      this.setBookingSelected(null)
      this.clearDataSearchForm()
      this.fillDataform(idRsv)
    },

    setNights(rangeDate) {
      if (this.searchForm.rangeDate.includes("to")) {
        const splitDate = rangeDate.split(" to ")
        this.searchForm.dateIn = splitDate[0] || moment(new Date()).format("YYYY-MM-DD")
        this.searchForm.dateOut = splitDate[1] || moment(new Date()).add(1, "days").format("YYYY-MM-DD")
        const inicio = moment(splitDate[0])
        const fin = moment(splitDate[1])
        this.searchForm.nights = splitDate[0] && splitDate[1] ? fin.diff(inicio, "days") : 0
      } else if (!!this.searchForm.dateIn && !!this.searchForm.dateOut) {
        this.searchForm.rangeDate = `${this.searchForm.dateIn} to ${this.searchForm.dateOut}`
        const inicio = moment(this.searchForm.dateIn)
        const fin = moment(this.searchForm.dateOut)
        this.searchForm.nights = fin.diff(inicio, "days")
      }
      else this.searchForm.nights = 0
    },
    createArrayNinios(ninios) {
      let ninos = [];
      ninios.forEach((ageNinio) => {
        ninos.push({ edad: ageNinio, key: stringAleatorio() });
      });
      return ninos;
    },
		setAdults(numberAdults) {
			let adultGuests = []
			if (!this.bookingSelected.guests.adults.find((guest) => guest.ismain == true))
				this.bookingSelected.guests.adults[0].ismain = true

			for (let i = 0; i < numberAdults; i++) {
				const key = stringAleatorio()
				let adultDetail = {
          idForm: key,
          id: null,
          mainGuest: false,
          NamePrefix: null,
          GivenName: 'N/A',
          Surname: 'N/A',
          PhoneNumber: null,
          Email: null,
          Address: null,
          guestType: `guest ${i + 1}`,
          Adult: true,
				}
				if (i === 0)
					adultDetail = {...this.bookingSelected.guests.adults.find((guest) => guest.ismain == true), key }
				if (Boolean(this.bookingSelected.guests.adults[i]))
					adultDetail = { ...this.bookingSelected.guests.adults[i], key }
					adultGuests.push(adultDetail)
			}
			this.bookingSelected.guests.adults = adultGuests
		},
		setChilds(numberChilds) {
			let dataChildrens = []

			for (let i = 0; i < numberChilds; i++) {
				const key = stringAleatorio()
				let childDetail = {
          idForm: key,
          id: null,
          GivenName: 'N/A',
          Surname: 'N/A',
          guestType: `child ${i + 1}`,
          Age: 1,
          Adult: false,
				}
				if (Boolean(this.bookingSelected.guests.childs[i]))
					childDetail = { ...this.bookingSelected.guests.childs[i], key }

					dataChildrens.push(childDetail)
			}
			this.bookingSelected.guests.childs = dataChildrens
		},
    async getBookings(searchForm) {
      const el = document.getElementById("footer");
      const { rangeDate, dateIn, dateOut, nights, idRsv } = searchForm;
      el.scrollIntoView({ behavior: "smooth" });
      const success = await this.$refs.searchFormbooking.validate()
      if (success) {
        if (!!rangeDate && !!dateIn && !!dateOut && nights > 0 && !!idRsv) {
          this.showGuests = false
          // this.setBookingSelected( null )
          this.$emit("search-rooms", searchForm);
        } else showAlertMessage(`Fecha: Check in - Check out`, "InfoIcon", `😰😰 Falta que se indique valor para Check in - Check out`, "danger", 3000,"bottom-right")
      }
    },
    updateBooking(){
      const {id, guests, orderDetail} = this.bookingSelected
      const payload = {
        ...this.searchForm,
        id, guests, orderDetail
      }
      this.$emit("update-booking", payload);
    },
    showGuestForm(boolean){
      this.showGuests = boolean
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-control[readonly]{
  background-color: white;
}
</style>
