<template>
  <ValidationObserver ref="observerBooking">
    <b-card no-body class="mb-0">
      <div class="">
        <!-- adults -->
        <div>
          <!-- Please indicate the age, name and lastname of each child -->
          <!-- <h6 class="mb-0">{{$t("Please indicate the age, name and lastname of each child")}}</h6>
          <label class="text-warning">{{$t("Please list each child's first and last name or leave it as NA")}}</label> -->
        </div>

        <div class="border-guests rounded p-1">
          <div class="d-flex justify-content-between clickable" @click="setShowGuest">
            <h6>Lista de huéspedes</h6>
            <feather-icon :icon="showGuests ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </div>
          <b-collapse id="guests-collapse" v-model="showGuests">
            <div v-if="guests.adults.length > 0">
              <span class="font-weight-bold">Adultos</span>
              <b-row v-if="showFormsLabel">
                <b-col>
                  <label class="">Nombre</label>
                </b-col>
                <b-col>
                  <label class="">Apellido</label>
                </b-col>
                <b-col>
                  <label class="">Email</label>
                </b-col>
                <b-col>
                  <label class="">Teléfono</label>
                </b-col>
              </b-row>
              <b-row class="mb-1" v-for="(adult, index) in guests.adults" :key="adult.key">
                <b-col md="3">
                  <b-row>
                    <b-col md="4" v-if="adult.mainGuest || index == 0">
                      <ValidationProvider name="Prefijo" rules="required">
                        <b-form-group slot-scope="{ errors }" class="mb-0">
                          <label v-if="!showFormsLabel">Prefijo</label>
                            <b-form-select
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              v-model="adult.NamePrefix"
                            >
                              <option value="">Seleccione una opción</option>
                              <option
                                v-for="(namesF, index) in namesPrefix"
                                :key="index"
                                :value="namesF.value"
                              > {{ namesF.text }}
                              </option>
                            </b-form-select>

                          <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                      <!-- </b-col>
                      <b-col class="form-group" md="3"> -->
                      <ValidationProvider name="Nombre" rules="required">
                        <b-form-group slot-scope="{ errors }" class="mb-0">
                          <label v-if="!showFormsLabel">Nombre</label>
                          <b-form-input
                            size="sm"
                            placeholder="Nombre"
                            v-model="adult.GivenName"
                            :state="errors.length > 0 ? false : null"
                          />
                          <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col class="form-group" md="3">
                  <ValidationProvider name="Apellido" rules="required">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Apellido</label>
                      <b-form-input
                        size="sm"
                        placeholder="Apellido"
                        v-model="adult.Surname"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col class="form-group" md="3" v-if="adult.Adult">
                  <ValidationProvider name="Email" :rules="adult.mainGuest || index == 0 ? 'required' : ''">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Email</label>
                      <b-form-input
                        size="sm"
                        placeholder="Email"
                        v-model="adult.Email"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                      />
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col class="form-group" md="3" v-if="adult.mainGuest || index == 0">
                  <ValidationProvider name="Phone" :rules="adult.mainGuest || index == 0 ? 'required' : ''">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Phone</label>
                      <b-form-input
                        size="sm"
                        placeholder="Phone"
                        v-model="adult.PhoneNumber"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col v-if="!showFormsLabel" class="border-bottom mb-1"/>
              </b-row>
            </div>

            <!-- <hr class="mt-0"> -->

            <!-- childs -->
            <div v-if="guests.childs.length > 0">
              <span class="font-weight-bold">Niños</span>
              <b-row v-if="showFormsLabel">
                <b-col>
                  <label>Nombre</label>
                </b-col>
                <b-col>
                  <label>Apellido</label>
                </b-col>
                <b-col>
                  <label>Edad</label>
                </b-col>
                <b-col>
                  <!-- <label>{{$t('Lastname') }}</label> -->
                </b-col>
              </b-row>
              <b-row class="row" v-for="child in guests.childs" :key="child.key">
                <b-col class="form-group" md="3">
                  <ValidationProvider name="Nombre" rules="required">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Nombre</label>
                      <b-form-input
                        size="sm"
                        placeholder="Nombre"
                        v-model="child.GivenName"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col class="form-group" md="3">
                  <ValidationProvider name="Apellido" rules="required">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Apellido</label>
                      <b-form-input
                        size="sm"
                        placeholder="Apellido"
                        v-model="child.Surname"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col class="form-group" md="3">
                  <ValidationProvider name="Edad" rules="required">
                    <b-form-group slot-scope="{ errors }" class="mb-0">
                      <label v-if="!showFormsLabel">Edad</label>
                      <b-form-select size="sm" v-model="child.Age">
                        <option v-for="(edad, idx) in ageChildrenOptions" :key="idx" :value="edad.value">
                          {{ edad.text }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback class="invalid-feedback-custom">{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col v-if="!showFormsLabel" class="border-bottom mb-1"/>
              </b-row>
            </div>
          </b-collapse>
        </div>
        <!-- end -->
      </div>
    </b-card>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import { namesPrefix, ageChildrenOptions } from '@/data/data'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {    
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    guests: {
      type: Object,
      required: true,
    },
    showGuests: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data(){
    return {
      namesPrefix,
      ageChildrenOptions,
      childages: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    }
  },
  computed:{
    ...mapGetters("appConfig", ["currentBreakPoint"]),

    showFormsLabel(){
      return !['xs', 'sm'].includes(this.currentBreakPoint)
    }
  },
  methods: {
    setDataInBooking(data){
      this.$emit('set-guests-in-properties', data)
    },
    cardTitle(data){
      const {guest, index} = data
      if (guest.mainGuest) return `Main Guest: ${guest.GivenName} ${guest.Surname}`
      else return guest.Adult ? `Adulto ${index+1}: ${guest.GivenName} ${guest.Surname}` : `Niño ${index+1}: ${guest.GivenName} ${guest.Surname}`
    },
    rulesRequired(data){
      const {index, adult} = data
      return adult && index < 0 ? 'required' : ''
    },
    setShowGuest(){
      this.$emit('set-show-guest', !this.showGuests)
    }
  }
}
</script>

<style>
.margin-custom-bookings-sales{
  margin-bottom: 0.4rem !important;
}
.border-guests{
  border: 1px solid #aaaaaa;
  margin-bottom: 2.5rem;
}
.invalid-feedback-custom{
  font-size: 75% !important;
}
</style>