<template>
  <div>
    <div v-if="!aBookingIsUpdating">
      <!-- <TableRoomBookings :bookingListUpdate="bookingListUpdate" /> -->
      <div v-if="orderDetailBookings.length > 0">
        <SearchForm
          :roomsData="orderDetailBookings"
          :isLoadingRooms="isLoadingRooms"
          :showDataTitular="showDataTitular"
          @search-rooms="searchRooms"
          @update-booking="updateGuestBooking"
        />

        <RoomsList :isLoadingRooms="isLoadingRooms"  @update-booking="updateMyBooking" />
      </div>
      <div v-else>
        <b-alert show variant="warning" class="p-1 text-center">
          No hay reservas válidas para editar
        </b-alert>
      </div>
    </div>
    <div class="text-center" v-if="aBookingIsUpdating">
      <b-spinner label="Loading..." variant="success"/><br>
      <strong>Actualizando reserva</strong>
    </div>
  </div>
</template>

<script>
import {  mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TableRoomBookings from '@/modules/mySales/components/orderDetail/bookings/TableRoomBookings'
import SearchForm from '@/modules/mySales/components/orderDetail/bookings/SearchForm'
import RoomsList from '@/modules/mySales/components/orderDetail/bookings/RoomsList'
import HeaderBooking from '@/modules/mySales/components/orderDetail/bookings/HeaderBooking'

import { setGuestsInPayload } from '@/helpers/shopHelper'
import { toJson, showAlertMessage, currentDate } from '@/helpers/helpers'
import * as moment from "moment"

export default {
  components: {
    TableRoomBookings,
    SearchForm,
    HeaderBooking,
    RoomsList
  },
  data(){
    return{
      isLoadingRooms: false
    }
  },
  computed: {
    ...mapGetters('mySales',['orderDetailBookings']),
    ...mapState('auth',['user']),
    ...mapState('mySales',['orderDetail','aBookingIsUpdating','bookingToEdit','bookingSelected', 'searchForm', 'selectedOrder']),
    listValidRsvs() {
      const bookings = [...this.orderDetailBookings];
      let valids = [];
      const statusBanned = ["Cancelled", "Annulled"]; // status no aceptados
      bookings.forEach( item => {
        const isValidDate = moment(item.booking.dateIn).isAfter(currentDate()) //verifico si la fecha de ingreso, es mayor la fecha actual
        //si status de item no está en los baneados y viene con dingusbooking
        if (!statusBanned.includes(item.orderdetailstatusname) && isValidDate) valids.push(item.id)
      })
      return valids?.length
    },
    showDataTitular(){
      const orderDetail = this.orderDetailBookings ? this.orderDetailBookings[0] : null
      if( orderDetail ){
        const seller = orderDetail?.saleuser_id == this.user?.idUser
        const channel = orderDetail?.saleuserchannelid == this.user?.canalventa?.id
        const owner = this.selectedOrder?.customer?.typeCustomer == 2

        const valid = ((seller || channel) && !owner) || false

        return valid
      }
      return false
    },
    bookingListUpdate(){
      if( !this.bookingToEdit ) return this.orderDetail.bookings
      if(this.bookingToEdit) return this.orderDetail.bookings.filter(item => item.booking.id === this.bookingToEdit )
    },
  },
  methods: {
    ...mapActions('mySales',['getRooms', 'updateBookingOwner', 'updateBooking']),
    ...mapMutations('mySales',['setRoomsOrderDetail','setDataSearchForm2', 'setaBookingIsUpdating', 'setDataSearchForm', 'setBookingSelectedRoom', 'unsetDataUserAuthorization', 'setBookingToEdit', 'clearDataSearchForm', 'setBookingSelected']),
    async searchRooms(rawData){
      let comision =toJson(...this.orderDetail.bookings.filter(item => item.booking.id === this.bookingToEdit ))
      let data = toJson(rawData)
      const promoCode = data.promoCode != null ? data.promoCode : ''
      const promoCodeManual = data.promoCodeManual != '' ? data.promoCodeManual : ''
      const promoCodeToUse = promoCode == '' ? promoCodeManual : promoCode
      //añado propiedades restantes
      data.children = data.numberChildren
      data.idioma = data.language || 'en'
      data.isManualRate = false
      data.manualRate = ''
      data.promotionCode = promoCodeToUse || ''
      data.promoCodeManual = promoCodeManual
      data.rateplanid = ''
      data.tarifa = ''
      data.serviceFee= comision.serviceFee

      this.setDataSearchForm2(data) //lo pongo en estado para ocuparlo como payload en peticion al resetear tarifas de un room
      this.isLoadingRooms = true
      const rooms = await this.getRooms( data )
      // console.log(formatBookings(rooms))
      this.setRoomsOrderDetail( rooms )
      this.isLoadingRooms = false
    },
    async updateMyBooking(payloadFinal){
      this.setaBookingIsUpdating(true) // para el loader
      this.setDataSearchForm(null) // limpio los datos del state searchForm, en nulo

      this.setBookingSelectedRoom( null ) // pongo nulo booking selected
      this.unsetDataUserAuthorization() // reseteo los valores del state userAuthUpdateBooking

      const { status, message } = await this.updateBooking(payloadFinal)
      if( status ){
        showAlertMessage('OK', 'BellIcon', message, 'success', 4000,  'bottom-right')
        this.$emit("update-booking");
        this.setBookingToEdit( null ) //muto bookingToEdit en nulo
        this.clearDataSearchForm()
        this.setBookingSelected(null)
      }
      if( !status ) showAlertMessage('Error', 'InfoIcon', message, 'danger', 4000, 'bottom-right')
      setTimeout(() => {
        this.setaBookingIsUpdating(false) // detengo el loader
      }, 1500)

    },
    async updateGuestBooking(bookingData){
      const { guests, id , orderDetail } = bookingData

      const newguests = setGuestsInPayload(guests)
      const idx = toJson( guests?.adults?.findIndex( main => main.mainGuest = true ))

      const payload = {
        id: id,
        detail: orderDetail,
        email: guests?.adults[idx]?.Email,
        givenname: guests?.adults[idx]?.GivenName,
        surname: guests?.adults[idx]?.Surname,
        nameprefix: guests?.adults[idx]?.NamePrefix,
        phonenumber: guests?.adults[idx]?.PhoneNumber,
        guests: newguests,
      }
      // console.log(payload)
      this.setaBookingIsUpdating(true) // para el loader
      const response = await this.updateBookingOwner(payload)
      if (response){
        this.$emit("update-booking");
        this.setBookingToEdit( null ) //muto bookingToEdit en nulo
        this.clearDataSearchForm()
        this.setBookingSelected(null)
      }
      setTimeout(() => {
        this.setaBookingIsUpdating(false) // detengo el loader
      }, 1500)
    }
  }
}
</script>

<style>

</style>