<template>
    <b-card class="border">
        <b-card-body>
            <b-card-title style="font-size:1em" class="text-justify">
                <b-link class="card-link" style="cursor:auto">
                    <strong>{{product.itemsold}} </strong>
                </b-link>
            </b-card-title>

            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <ItineraryForm
                    v-if="product.itinerary.id"
                    :itinerario="product.itinerary"
                    :product="product"
                    :isAnEdition="true"
                    :isDisablebToEdit="isDisablebToEdit"
                    :isValidTime="isValidTime"
                    @set-is-valid-time="setIsValidTime"
                />
                <div class="float-right pt-1">
                    <b-button
                        v-if="!isDisablebToEdit"
                        variant="primary"
                        :disabled="invalid || isValidTime || isUpdatingItinerary || product.transafer !=null "
                        @click="saveDataTransferItinerary(product.id, product.itinerary)"
                    > Guardar
                    <b-spinner
                        label="Loading..."
                        variant="success"
                        small
                        v-if="isUpdatingItinerary"
                    />
                    </b-button>
        
                </div>            
            </ValidationObserver>

        </b-card-body>
    </b-card>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
// import vSelect from 'vue-select'
import * as moment from 'moment'
import { currentDate, showAlertMessage } from '@/helpers/helpers'
import ItineraryForm from '@/modules/shop/components/orderSummary/itinerary/itineraryForm.vue'
import { utils } from '@/modules/mySales/mixins/utils'

export default {
	mixins:[utils],
    created(){
    },
    components:{
        ItineraryForm
    },
    props:{
        product:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isUpdatingItinerary: false,
            fechaActual: currentDate(),
            isValidTime: false,
        }
    },
    computed: {
        ...mapState('auth',['user']),
        ...mapState('start',['destinations', 'airlines']),
        //si return true, es editable
        //si isDisablebToEdit return true, no es editable
        isDisablebToEdit(){
            return this.lockMySalesEditForms(this.product)
        },
    },
    methods: {
        ...mapActions('mySales',['updateItineraryDetail']),

        async saveDataTransferItinerary(id, itinerary){
            this.isUpdatingItinerary = true
            const itinerario = {
                airline: itinerary.airline.name ? itinerary.airline.name : itinerary.airline || '',
                flight: itinerary.flight,
                flighttime: itinerary.flighttime ? itinerary.flighttime : '',
                idUser: this.user.idUser,
                ifrom: itinerary.originPlaceDisplay,
                ito: itinerary.destinationPlaceDisplay,
                lastNameBenefi: itinerary.lastNameBenefi,
                nameBenefi: itinerary.nameBenefi,
                ordersdetail: id,
                pax: itinerary.pax,
                pickup: itinerary.pickup ?  itinerary.pickup : '',
                trip: itinerary.trip,
                typeflight:itinerary.typeflight,
            }
            if(this.isDisablebToEdit){
                this.isUpdatingItinerary = false
                return showAlertMessage(`No se puede realizar la actualización`, "InfoIcon", `😰😰 No puede actualizar el transfer el mísmo dia de su operación, despúes de las 4:00 PM un día antes de la fecha de operación, si la venta no lo hizo usted mismo o no están en el mismo canal.`, "danger", 7000, "bottom-right" )
            }       
            await this.updateItineraryDetail(itinerario)

            this.isUpdatingItinerary = false

        },
        setIsValidTime( boolean ){
            this.isValidTime = boolean
        }
    }
}
</script>